import axios, { AxiosError, AxiosResponse } from "axios";
import { ServerResponseError } from "@/shared/connection/ServerResponseError";

export const ajaxCall = axios.create({
    baseURL: `${process.env.VUE_APP_ORIGIN}/api/`,
    withCredentials: true,
});

ajaxCall.interceptors.response.use(
    (res: AxiosResponse) => {
        // Unwrap response if possible:
        return res?.data || res;
    },
    (errorData: AxiosError) => {
        return Promise.reject(new ServerResponseError(errorData));
    }
);
