import { ajaxCall } from "@/shared/connection/ajax-call";
import { handleErrorByMessage } from "@/shared/connection/with-store-error-handlers";
import { isProdOrStageEnvironment } from "@/environments";
import { makeLogger } from "@/shared/consola";
import store from "@/store";
import { darkModeService } from "@/shared/themes/dark-mode.service";

const ENDPOINT = "/user";

const logger = makeLogger("userService");

export const userService = {
    async forgeMagicLink(redirectUrl) {
        logger.debug("start to forgeMagicLink", redirectUrl);
        if (store.getters.isImp) {
            logger.debug("Imp session, not forging magic link, result => ", redirectUrl);
            return { magicUrl: redirectUrl };
        }
        if (isProdOrStageEnvironment) {
            return ajaxCall
                .post(`${ENDPOINT}/forge-magic`, { redirectUrl })
                .then((link) => {
                    logger.debug("link forged", link.magicUrl);
                    link.magicUrl = wrapLinkWithThemeAndLang(link.magicUrl);
                    logger.debug("link wrapped with current theme nad lang...", link.magicUrl);
                    return link;
                })
                .catch(handleErrorByMessage());
        }
        // I will not forge magic link for dev environment;
        logger.trace("DEV, not forging magic link, result => ", redirectUrl);
        return { magicUrl: redirectUrl };
    },
    getTenantDetails() {
        logger.debug("getTenantDetails");
        return ajaxCall.get(`${ENDPOINT}/tenant-details`).catch(handleErrorByMessage());
    },
};

function wrapLinkWithThemeAndLang(link) {
    try {
        const url = new URL(link);
        url.searchParams.set("theme", darkModeService.getCurrentMode());
        url.searchParams.set("lang", store.state.lang);
        return url.toString();
    } catch {
        console.warn("Could not wrap", link, "with theme + lang...");
        return link;
    }
}
