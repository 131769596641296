import { localStorageService, STORAGE_KEY } from "@/shared/connection/local-storage.service";
import { makeLogger } from "@/shared/consola";
import { useRoute } from "vue-router";

const logger = makeLogger("dark-mode.service");
const DARK_CLASS_NAME = "dark";

export const darkModeService = {
    isDarkMode: false,
    init() {
        this.isDarkMode =
            this.setupBasedOnQuery() ??
            this.getModeFromLocalStorage() ??
            this.discoverDarkModeFromNavigator();
        this.setMode();
        logger.trace(
            `🎨 UI theme init() - done: we got ${this.isDarkMode ? "dark 🌙" : "light ☀️"} theme settled 🎨`
        );
    },
    isCurrentModeDark() {
        return this.isDarkMode;
    },
    getCurrentMode() {
        return this.isDarkMode ? "dark" : "light";
    },
    getModeFromLocalStorage(): boolean | null {
        return localStorageService.readValue(STORAGE_KEY.darkMode) as boolean | null;
    },
    setupBasedOnQuery(): boolean | undefined {
        const { query } = useRoute();
        if (!query.theme) {
            // no theme in query - return undefined on purpose
            return;
        }
        const isDark = query.theme === DARK_CLASS_NAME;
        // CAREFULLY! SIDE EFFECT, when a theme is present, we need to accept it, and then remove + save in localStorage;
        this.saveModeInLocalStorage(isDark);
        return isDark;
    },
    discoverDarkModeFromNavigator(): boolean {
        return window?.matchMedia?.("(prefers-color-scheme: dark)")?.matches;
    },
    saveModeInLocalStorage(overwrite?: boolean) {
        // overwrite logic is only for internal use of setupBasedOnQuery!
        return localStorageService.saveValue(STORAGE_KEY.darkMode, overwrite ?? this.isDarkMode);
    },
    setMode() {
        const app = document.querySelector("#app") as HTMLDivElement;
        if (this.isDarkMode) {
            app.classList.add(DARK_CLASS_NAME);
            // document.querySelector("html").classList.remove("bg-gray-50");
            document.querySelector("html")?.classList.add(DARK_CLASS_NAME);
            // , "bg-gray-800");
        } else {
            app.classList.remove(DARK_CLASS_NAME);
            document.querySelector("html")?.classList.remove(DARK_CLASS_NAME);
            // , "bg-gray-800");
            // document.querySelector("html").classList.add("bg-gray-50");
        }
    },
    toggleDarkMode() {
        logger.trace("toggleDarkMode");
        this.isDarkMode = !this.isDarkMode;
        this.setMode();
        this.saveModeInLocalStorage();
    },
};

localStorageService.subscribeOnChange(STORAGE_KEY.darkMode, ({ newValue, oldValue }) => {
    if (newValue !== oldValue) {
        logger.trace("DarkMode change in other tab/window, reloading to:", newValue);
        // init() is reserved to components -> so we do it like that:
        darkModeService.isDarkMode = newValue === "true";
        darkModeService.setMode();
    }
});
