import { createRouter, createWebHistory } from "vue-router";

import { authRoutes } from "@/auth/auth.routes";
import { appRoutes } from "@/router/appRoutes";
import { onNavigateAwayGuards } from "@/router/onNavigateAway.guard";
import { ENV_TYPES } from "@/environments";

const RenderNotFound = () =>
    import(/* webpackChunkName: "render" */ "@/views/render/RenderNotFound.vue");

const { VUE_APP_DEV_START_TOOL, NODE_ENV } = process.env;

const routes = [
    // This element is a redirect to the default app when in development mode.
    NODE_ENV === ENV_TYPES.development && VUE_APP_DEV_START_TOOL
        ? { path: "", redirect: `/${VUE_APP_DEV_START_TOOL}/projects-list` }
        : {}, // make VUE_APP_DEV_START_TOOL a default app for now.
    { ...authRoutes },
    ...appRoutes,
    { path: "/:catchAll(.*)", component: RenderNotFound },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;

router.beforeEach((to, from) => {
    return onNavigateAwayGuards(to, from);
});
