import { ajaxCall } from "@/shared/connection/ajax-call";
import { handleErrorByMessage } from "@/shared/connection/with-store-error-handlers";
import { makeLogger } from "@/shared/consola";
// eslint-disable-next-line no-unused-vars
const logger = makeLogger("user-themes.service");

const THEMES_ENDPOINT = `/user/themes`;

export const userThemesService = {
    THEME_USER_ID: "user",
    create(theme) {
        logger.info("sending", theme, "to", THEMES_ENDPOINT);
        return ajaxCall.post(THEMES_ENDPOINT, theme).catch(handleErrorByMessage());
    },
    getAllForUser() {
        return ajaxCall.get(THEMES_ENDPOINT).catch(handleErrorByMessage());
    },
    prepareThemeDto(
        name,
        { font, isDefault, logo, logoSquare },
        { background, border, brand, cardBackground, cardText, text }
    ) {
        return {
            name,
            colors: {
                background,
                border,
                brand,
                cardBackground,
                cardText,
                text,
            },
            font,
            isDefault: isDefault || false,
            logo,
            logoSquare,
        };
    },
};
