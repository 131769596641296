import { loginService } from "@/auth/service/login.service";
import store from "@/store";
import { makeLogger } from "@/shared/consola";
import { RENDER_ROUTE_NAMES } from "@/views/render/render.routes";
import router from "@/router";

import { t } from "@/i18n";
import { userService } from "@/shared/user/user.service";
import { ServerResponseError } from "@/shared/connection/ServerResponseError";

const { SPECIAL_ERROR_TOKEN } = ServerResponseError;

const logger = makeLogger("handleErrorByMessage");

export function handleErrorByMessage({ rethrow } = { rethrow: true }) {
    return (error) => {
        logger.debug(error);
        if (ServerResponseError.checkPublishLimitReached(error)) {
            store.dispatch("buyPro");
        } else {
            store.dispatch("showServerError", error.translatedMessage || error);
        }
        if (rethrow) {
            throw error;
        }
    };
}

export function handleErrorNotAnOwner() {
    return async (error) => {
        const { lang } = store?.state || {};
        if (
            [
                SPECIAL_ERROR_TOKEN.ERR_PROJECTS_NOT_AN_OWNER,
                SPECIAL_ERROR_TOKEN.ERR_USER_NOT_AN_OWNER,
            ].includes(error.errorToken)
        ) {
            // assure user present in store:
            const userReady = await store.dispatch("assureUserPresent");
            if (!userReady) {
                await store.dispatch("getUser");
            }
            if (store.getters["isUserRegistered"]) {
                const decision = await store.dispatch("modals/showConfirmModal", {
                    title: t("shared.access.info_unathorized"),
                    description: t("shared.access.info_unathorized_desc"),
                    ctaLabel: t("shared.go_to_dashboard"),
                    cancelLabel: t("shared.log_out"),
                    size: "xl",
                });
                if (decision) {
                    // goto dashboard...
                    const { magicUrl } = await userService.forgeMagicLink(
                        store.getters["activeAppDashboardLink"]
                    );
                    window.location = magicUrl;
                    return;
                }
                // logout and redirect to dashboard
                await loginService.logOutAndRedirectTo(
                    store.state.lang,
                    store.getters["activeAppDashboardLink"]
                );
                return;
            }
            // When a user has no registered account:
            // - wait 2 sec and redirect to login page
            setTimeout(() => {
                window.location.href = loginService.generateLogInLink(lang);
            }, 2000);
            const loginError = {
                errorToken: SPECIAL_ERROR_TOKEN.ERR_LOGIN_REQUIRED,
                translatedMessage: t(`server.error.${SPECIAL_ERROR_TOKEN.ERR_LOGIN_REQUIRED}`),
            };
            handleErrorByMessage()(loginError);
        } else {
            handleErrorByMessage()(error);
        }
    };
}

export function handleErrorNotPublished() {
    return (error) => {
        if (window.location === window.parent.location) {
            if (error.errorToken === SPECIAL_ERROR_TOKEN.ERR_ACCESS_PLAN_BELOW_REQUIRED) {
                router.push({ name: RENDER_ROUTE_NAMES.notPublished });
            } else {
                router.push({ name: RENDER_ROUTE_NAMES.notFound });
            }
        } else {
            handleErrorByMessage()(error);
        }
    };
}

export function handleErrorUuidTaken() {
    return (error) => {
        if (error === SPECIAL_ERROR_TOKEN.UUID_TAKEN) {
            store.commit("showAlert", {
                type: "wrong",
                title: t(`server.error.${SPECIAL_ERROR_TOKEN.UUID_TAKEN}`),
            });
            throw error;
        } else {
            handleErrorByMessage()(error);
        }
    };
}
