export enum ENV_TYPES {
    development = "development",
    production = "production",
    // staging = "staging",
}

export const isDevEnvironment = process.env.NODE_ENV === ENV_TYPES.development;
export const isStagingEnvironment = Boolean(process.env.VUE_APP_STAGING_ENV);

// NODE_ENV is always production after build!!!
export const isProdOrStageEnvironment = process.env.NODE_ENV === ENV_TYPES.production;
