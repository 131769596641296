<template>
    <div id="app" class="relative min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <!--        <div
            class="bg-gray-100 dark:bg-gray-900 px-4 rounded-md absolute top-6 right-6 text-center flex items-center"
        >
            <p
                class="font-medium cursor-pointer p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
                @click="setLanguage('pl')"
                v-if="lang === 'en'"
            >
                Zmień język
            </p>
            <p
                class="font-medium cursor-pointer p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
                @click="setLanguage('en')"
                v-if="lang === 'pl'"
            >
                Change language
            </p>
            <span class="ml-1 mr-2 text-gray-400">|</span>
            <div class="cursor-pointer" @click="toggleDarkMode">
                <MoonIcon
                    v-if="darkMode"
                    class="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 h-6 w-6"
                />
                <SunIcon
                    v-else
                    class="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 h-6 w-6"
                />
            </div>
        </div>-->
        <!-- <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                class="dark:hidden mx-auto h-12 w-auto"
                src="@/shared/assets/easytools.svg"
            />
            <img
                class="hidden dark:block mx-auto h-12 w-auto"
                src="@/shared/assets/easytools-white.svg"
            />
        </div> -->

        <div class="sm:mx-auto sm:w-full sm:max-w-lg">
            <div class="dark:bg-gray-900 py-8 px-4 sm:px-10">
                <router-view :redirectUrl="redirectUrl"></router-view>
            </div>
        </div>
        <LayoutAlerts v-model="messages" />
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Query from "@/shared/utils/router/query";
import { darkModeService } from "@/shared/themes/dark-mode.service";
import { LayoutAlerts } from "@easy/ui/layouts";

export default {
    name: "AuthApp",
    components: {
        LayoutAlerts,
    },
    data() {
        return {
            darkMode: false,
            redirectUrl: "",
        };
    },
    computed: {
        ...mapState(["lang", "user"]),
        ...mapState("alert", ["messages"]),
    },
    mounted() {
        const query = new Query(window.location.search);
        this.redirectUrl = query.get("redirect");

        document.querySelector("html").classList.add("h-full");
        document.querySelector("body").classList.add("h-full");

        this.darkMode = darkModeService.isCurrentModeDark();
    },
    methods: {
        ...mapMutations(["setLanguage"]),
        toggleDarkMode() {
            this.darkMode = !this.darkMode;
            darkModeService.toggleDarkMode();
        },
    },
};
</script>
