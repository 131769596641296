import { makeLogger } from "@/shared/consola";
const logger = makeLogger("local-storage.service");

const storageArea = localStorage;

export const STORAGE_KEY = {
    userId: "userId",
    darkMode: "DarkMode",
    language: "Language",
    closeBanner: "CloseBanner",
    closeOnboardingVideos: "CloseOnboardingVideos",
    closeHelpVideos: "CloseHelpVideos",
} as const;

type StorageKey = (typeof STORAGE_KEY)[keyof typeof STORAGE_KEY];

export const prefixedKey = (key: StorageKey) => `et.${key}`;
const legacyPrefixKey = (key: StorageKey) => `easyTools${key}`;

export const localStorageService = {
    saveValue<V>(key: StorageKey, value: V) {
        if (value !== null && typeof value === "object") {
            storageArea.setItem(prefixedKey(key), JSON.stringify(value));
        } else {
            storageArea.setItem(prefixedKey(key), String(value));
        }
    },
    removeValue(key: StorageKey) {
        storageArea.removeItem(prefixedKey(key));
        // remove legacy Prefix:
        storageArea.removeItem(legacyPrefixKey(key));
        logger.trace("removeValue", key);
    },
    readValue(key: StorageKey) {
        let value = storageArea.getItem(prefixedKey(key));
        if (value === null) {
            // get item with legacy Prefix:
            value = storageArea.getItem(legacyPrefixKey(key));
        }
        return mapValueToProperType(value);
    },
    // This WORKS only on "Other TAB" or browser window
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    subscribeOnChange(
        observeKey: StorageKey,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        fireOnChange = (_: Pick<StorageEvent, "newValue" | "oldValue">) => {}
    ) {
        window.addEventListener("storage", ({ storageArea, key, oldValue, newValue }) => {
            if (storageArea === storageArea && key === prefixedKey(observeKey)) {
                fireOnChange({ newValue, oldValue });
            }
        });
    },
};

/**
 * We read values from storage area as serialized strings mostly,
 * so that is in case of values - to make them work as proper types
 *
 * -->o Cast form String to other type, if type is a String, otherwise - leve it as is.
 * */
function mapValueToProperType<T>(value: T) {
    switch (value) {
        case "null":
        case null:
            return null;
        case "undefined":
        case undefined:
            return undefined;
        case "true":
        case true:
            return true;
        case "false":
        case false:
            return false;
    }
    const castToNumber = Number(value);
    if (!Number.isNaN(castToNumber)) {
        return castToNumber;
    }
    return value;
}
