import { makeLogger } from "@/shared/consola";
import { ajaxCall } from "@/shared/connection/ajax-call";

const logger = makeLogger("shared-rest-calls.service");

const SHARED_ENDPOINT = `/shared`;

export const sharedRestCallsService = {
    makeShortLink(url) {
        logger.trace("makeShortLink", url);
        return ajaxCall.post(`${SHARED_ENDPOINT}/short-link`, { url });
    },
    getFonts() {
        logger.trace("getFonts");
        return ajaxCall.get(`${SHARED_ENDPOINT}/fonts`);
    },
};
