import store from "@/store";
import { computed, App } from "vue";

export default {
    install: (app: App) => {
        app.config.globalProperties.$l = (val: Record<"pl" | "en", string>) => {
            return val[store.state.lang] || val["en"]; //english fallback
        };
        app.config.globalProperties.lang = computed(() => store.state.lang);
    },
};
