import { nextTick } from "vue";
import mitt, { EventType, Handler } from "mitt";

const emitter = mitt();

export const EventBus = {
    $on: (type: EventType, handler: Handler) => emitter.on(type, handler),
    $off: (type: EventType, handler: Handler) => emitter.off(type, handler),
    $once: (type: EventType, handler: Handler) => {
        const handlerPatch: Handler = (ev: unknown) => {
            handler(ev);
            emitter.off(type, handlerPatch);
        };
        emitter.on(type, handlerPatch);
    },
    $emit: <E>(type: EventType, event: E) => emitter.emit(type, event),
    $nextTick: (handler: () => void) => nextTick(handler),
};
