import { t } from "@/i18n";
import { userThemesService } from "@/shared/themes/user-themes.service";

export const presetThemesService = {
    getAllColorPresets() {
        return [
            {
                id: userThemesService.THEME_USER_ID,
                name: t("shared.themes.preset_color.custom"),
            },
            {
                id: "light",
                name: t("shared.themes.preset_color.light"),
                font: "Inter",
                colors: {
                    brand: "#101828",
                    background: "#f9fafb",
                    text: "#101828",
                    cardBackground: "#ffffff",
                    cardText: "#101828",
                    border: "#d0d5dd",
                },
            },
            {
                id: "dark",
                font: "Inter",
                name: t("shared.themes.preset_color.dark"),
                colors: {
                    brand: "#ffffff",
                    background: "#141416",
                    text: "#fafafa",
                    cardBackground: "#1a1a1e",
                    cardText: "#fafafa",
                    border: "#3f3f46",
                },
            },
        ];
    },
    getAllStylePresets() {
        return [
            {
                id: "default",
                value: t("shared.themes.preset_style.default"),
                font: "Inter",
                radius: "6",
            },
            {
                id: "friendly",
                value: t("shared.themes.preset_style.friendly"),
                font: "Nunito",
                radius: "10",
            },
            {
                id: "classic",
                value: t("shared.themes.preset_style.classic"),
                font: "Playfair Display",
                radius: "0",
            },
            {
                id: "tech",
                value: t("shared.themes.preset_style.tech"),
                font: "Roboto",
                radius: "4",
            },
        ];
    },
};
