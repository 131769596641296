import { localStorageService, STORAGE_KEY } from "@/shared/connection/local-storage.service";
import { makeLogger } from "@/shared/consola";
import { ServerResponseError } from "@/shared/connection/ServerResponseError";
import store from "@/store";
import { i18n } from "@/i18n";
import { useRoute } from "vue-router";
import { EventBus } from "@/shared/projects/event-bus";

const logger = makeLogger("language.service");

export enum SUPPORTED_LANGUAGES {
    pl = "pl",
    en = "en",
}

export type SupportedLanguages = `${SUPPORTED_LANGUAGES}`;

// Immediately listen to userReady to AVOID racing conditions:
EventBus.$once("userReady", () => {
    logger.debug("userReady happened");
    const { updatedFromQuery } = languageService.newLang;
    logger.debug("lang updatedFromQuery?", updatedFromQuery);
    if (updatedFromQuery) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (store.state.user.language !== updatedFromQuery) {
            store.dispatch("updateLanguage", updatedFromQuery);
        } else {
            // @fix: the language is not updated, but it needs to be set:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            store.commit("setLanguage", store.state.user.language);
        }
    }
});

export const languageService = {
    fallbackLanguage: SUPPORTED_LANGUAGES.pl,
    availableLanguages: Object.values(SUPPORTED_LANGUAGES) as SupportedLanguages[],
    newLang: {
        calculatedFromNavigator: "",
        updatedFromQuery: "",
    },
    init() {
        const updatedByQuery = this.setupBasedOnQuery();
        if (!updatedByQuery) {
            const lang = this.getLanguageFromLocalStorageOrNavigator();
            store.commit("setLanguage", lang);
        }
        logger.debug("🚩 language init() - done");
    },
    discoverNavigatorLanguage() {
        const navigatorLang = /^pl\b/.test(navigator.language)
            ? SUPPORTED_LANGUAGES.pl
            : SUPPORTED_LANGUAGES.en;
        // we need to know, that language was read from here
        this.newLang.calculatedFromNavigator = navigatorLang;
        return navigatorLang;
    },
    getLanguageFromLocalStorage() {
        return localStorageService.readValue(STORAGE_KEY.language);
    },
    getLanguageFromLocalStorageOrNavigator() {
        return this.getLanguageFromLocalStorage() || this.discoverNavigatorLanguage();
    },
    setupBasedOnQuery() {
        const { query = {} } = useRoute() || {};
        const { lang } = query;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!this.availableLanguages.includes(lang)) {
            // lang must be present in query, and be available if not...
            return false;
        }
        // side effect: update user language on BACK-END, but only when user ready:
        // this must be handled by $once("userReady") event listener above ⬆
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.newLang.updatedFromQuery = lang;
        return true;
    },
    setLanguageInLocalStorage(value: SupportedLanguages) {
        return localStorageService.saveValue(STORAGE_KEY.language, value);
    },
    setLanguageInLocalStorageAndErrorLang(value: SupportedLanguages) {
        logger.debug("setLanguageInLocalStorageAndErrorLang", value);
        this.setLanguageInLocalStorage(value);
        ServerResponseError.setAjaxErrorLanguage(value);
        i18n.global.locale.value = value;
    },
    updateBasedOnBackEnd(user: { language: SupportedLanguages }) {
        const { language } = user;
        if (!this.availableLanguages.includes(language)) {
            return;
        }
        const { calculatedFromNavigator, updatedFromQuery } = this.newLang;
        if (!updatedFromQuery && calculatedFromNavigator && calculatedFromNavigator !== language) {
            // only when we discovered lang by navigator, and differs form back-end, we need to override it:
            this.newLang.calculatedFromNavigator = "";
            store.commit("setLanguage", language);
        }
    },
};

localStorageService.subscribeOnChange(STORAGE_KEY.language, ({ newValue, oldValue }) => {
    // Reload language form local storage
    if (newValue !== oldValue) {
        logger.trace("language change in other tab/window, reloading to:", newValue);
        store.commit("setLanguage", newValue);
    }
});
