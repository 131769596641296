import { unwrapPaginatedResponse } from "../connection/service.helpers";
import { ajaxCall } from "@/shared/connection/ajax-call";
import {
    handleErrorByMessage,
    handleErrorUuidTaken,
    handleErrorNotAnOwner,
} from "@/shared/connection/with-store-error-handlers";
import { ToolAppTypes } from "@/shared/tool-app.types";

const PROJECT_ENDPOINT = `/projects`;

export const projectsService = {
    getAllForUser({ tool, page, size }: { tool: ToolAppTypes; page: number; size: number }) {
        return (
            ajaxCall
                .get(`${PROJECT_ENDPOINT}/${tool}`, { params: { page, size } })
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .then(unwrapPaginatedResponse)
                .catch(handleErrorByMessage())
        );
    },
    getOne({ tool, uuid }: { tool: ToolAppTypes; uuid: string }) {
        return ajaxCall.get(`${PROJECT_ENDPOINT}/${tool}/${uuid}`).catch(handleErrorNotAnOwner());
    },
    getOneWithSnapshot({ tool, uuid }: { tool: ToolAppTypes; uuid: string }) {
        return ajaxCall
            .get(`${PROJECT_ENDPOINT}/${tool}/${uuid}/snapshot`)
            .catch(handleErrorNotAnOwner());
    },
    getOneForRender({ tool, uuid }: { tool: ToolAppTypes; uuid: string }) {
        return ajaxCall
            .get(`${PROJECT_ENDPOINT}/${tool}/${uuid}/render`)
            .catch(handleErrorByMessage());
    },
    create({ tool, name }: { tool: ToolAppTypes; name: string }) {
        return ajaxCall.post(`${PROJECT_ENDPOINT}/${tool}`, { name }).catch(handleErrorByMessage());
    },
    update<T>({ tool, uuid, data }: { tool: ToolAppTypes; uuid: string; data: T }) {
        return ajaxCall
            .patch(`${PROJECT_ENDPOINT}/${tool}/${uuid}`, data)
            .catch(handleErrorByMessage());
    },
    rename({ tool, uuid, name }: { tool: ToolAppTypes; uuid: string; name: string }) {
        return ajaxCall
            .patch(`${PROJECT_ENDPOINT}/${tool}/${uuid}/name`, { name })
            .catch(handleErrorByMessage());
    },
    remove({ tool, uuid }: { tool: ToolAppTypes; uuid: string }) {
        return ajaxCall.delete(`${PROJECT_ENDPOINT}/${tool}/${uuid}`).catch(handleErrorByMessage());
    },
    clone({ tool, uuid, name }: { tool: ToolAppTypes; uuid: string; name: string }) {
        return ajaxCall
            .post(`${PROJECT_ENDPOINT}/${tool}/${uuid}/clone`, { name })
            .catch(handleErrorByMessage());
    },
    changeUuid({ tool, uuid, newUuid }: { tool: ToolAppTypes; uuid: string; newUuid: string }) {
        return ajaxCall
            .patch(`${PROJECT_ENDPOINT}/${tool}/${uuid}/uuid`, { newUuid })
            .catch(handleErrorUuidTaken());
    },
    publish({ tool, uuid, publish }: { tool: ToolAppTypes; uuid: string; publish: boolean }) {
        return ajaxCall
            .patch(`${PROJECT_ENDPOINT}/${tool}/${uuid}/publish`, { publish })
            .catch(handleErrorByMessage());
    },
};
